<template>
    <div>
        <img class="example-headImg" src="../assets/image/example/02-3banner.png"/>
        <div class="head-explain">
            <div class="head-explain-title">DPM数字化工单</div>
            <div class="head-explain-content">实现工单科学管理，提升生产稳定性</div>
        </div>
        <div class="head-list">
            <div class="head-list-item head-list-item-ischeck" style="margin-left:26.3rem"><span class="title">需求场景</span></div>
            <div class="head-list-item"><span class="title">解决方案</span></div>
            <div class="head-list-item"><span class="title">方案收益</span></div>
        </div>
        <div class="container-1" style="display:flex">
            <div class="purchase-content-left">
                <div class="purchase-title">需求场景</div>
            </div>
            <div class="purchase-content-right">
                <div class="purchase-title">设备信息离散</div>
                <div class="purchase-content">设备资料都以纸质形式或公共盘的形式保存，现场维护工作时，无法及时查看资料</div>
                <div class="purchase-title">问题反馈效率慢</div>
                <div class="purchase-content">问题反馈速率慢，导致人机、物料等待延时，造成损失</div>
                <div class="purchase-title">工作完成质量不易追踪</div>
                <div class="purchase-content">任务完成质量不易把控，设备运行容易出现故障，影响生产效率</div>
                <div class="purchase-title">流程自动化程度低</div>
                <div class="purchase-content">线下交叉沟通时间长，信息传递不及时</div>
                <div class="purchase-title">维护经验知识沉淀难</div>
                <div class="purchase-content">维修记录反馈均为纸质，无法形成维修经验知识库，不能及时生成各项指标及管理报表</div>
                <div class="purchase-title">管理信息断层</div>
                <div class="purchase-content">管理层无法直接了解到各个工厂的实际生产情况，工厂间绩效缺少比较与竞争</div>
                <div class="purchase-title">数据分析效率低</div>
                <div class="purchase-content">各项数据整合分析困难，维护情况和设备停机时间无法建立联系</div>
            </div>
        </div>
        <div class="container-2" style="display:flex;height: 88rem;">
            <div class="purchase-content-left">
                <div class="purchase-title">解决方案</div>
            </div>
            <div class="purchase-content-right">
                <img class="solution-img" src="../assets/image/example/02-3解决方案.svg"/>
            </div>
        </div>
        <div class="container-1" style="display:flex">
            <div class="purchase-content-left">
                <div class="purchase-title">方案收益</div>
            </div>
            <div class="purchase-content-right">
                <img class="benefits-img" src="../assets/image/example/02-3方案收益.svg"/>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'BizfocusPagesPurchaseCollaboration',

    data() {
        return {

        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style lang="less" scoped>
.purchase-content-left{
    width:33rem;
    text-align: left;
    margin-left: 26rem;
    margin-top: 5.9rem;

}
.purchase-title{
    height: 2rem;
    font-size: 2rem;
    font-family: 'CN_Medium';
    font-weight: 500;
    color: #000000;
    line-height: 3rem;
}
.purchase-content{
    height: 7.7rem;
    font-size: 1.6rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #666666;
    line-height: 2.6rem;

    margin: 1.3rem 0 0 0.2rem;
}
.purchase-content-right{
    text-align: left;
    margin-top: 5.9rem;
    padding-bottom: 2rem;
}
.solution-img{
    width: 106.2rem;
    height: 75.9rem;
}
.benefits-img{
    width: 80.5rem;
    height: 33.6rem;
}
</style>
